import styles from './Filters.module.scss'
import '../../select.scss'
import { MultiSelect } from "react-multi-select-component"
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg"
import { ReactComponent as FiltersIcon } from "../../assets/icons/filters.svg"
import { ReactComponent as SaveIcon } from "../../assets/icons/excel.svg"
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg"
import React, { FC, useEffect, useRef, useState } from "react"
import { SwipeableList, SwipeableListItem } from "@sandstreamdev/react-swipeable-list"
import { useInputFocus } from "./InputFocusContext"
import {IFilters, IFiltersAll} from "../../app/store/users/user.type"
import {TGUser} from "../../functions/global";
import {Modal} from "../Modal";

interface FiltersProps {
	value: IFiltersAll,
	params: IFiltersAll,
	onSearchChange: (value: string) => void,
	onCitiesChange: (cities: IFilters[]) => void,
	onBlogThemeChange: (blogTheme: IFilters[]) => void,
	onSubscribersRangesChange: (min: string, max: string) => void,
	onGenderChange: (gender: IFilters[]) => void,
	onSkillsChange: (skills: IFilters[]) => void
}

export const Filters: FC<FiltersProps> = ({ value,
																						params,
																						onSearchChange,
																						onCitiesChange,
																						onBlogThemeChange,
																						onSubscribersRangesChange,
																						onGenderChange,
																						onSkillsChange }) => {
	const [search, setSearch] = useState<string>(value.search || '');
	const [cities, setCities] = useState<IFilters[]>(value.cities);
	const [blogTheme, setBlogTheme] = useState<IFilters[]>(value.blogTheme);
	const [gender, setGender] = useState<IFilters[]>(value.gender);
	const [skills, setSkills] = useState<IFilters[]>(value.skills);
	const [rightBar, setRightBar] = useState<{ visible: boolean, right: string }>({ visible: false, right: "-100%" });
	const [progressState, setProgressState] = useState<boolean>(false);
	const [modalVisible, setModalVisible] = useState<boolean>(false);
	const [modalContent, setModalContent] = useState<string>('');
	const { inputRef } = useInputFocus();
	const searchTimeoutRef = useRef<NodeJS.Timeout | null>(null);
	const [subscribersRangesMin, setSubscribersRangesMin] = useState<string>(value.subscribersRanges?.split('-')?.[0] || '')
	const [subscribersRangesMax, setSubscribersRangesMax] = useState<string>(value.subscribersRanges?.split('-')?.[1] || '')


	useEffect(() => {
		inputRef.current?.focus();
	}, [search]);

	useEffect(() => {
		if (!rightBar.visible) {
			onCitiesChange(cities);
			onBlogThemeChange(blogTheme);
			onSubscribersRangesChange(subscribersRangesMin, subscribersRangesMax);
			onGenderChange(gender);
			onSkillsChange(skills);
		}
	}, [rightBar]);

	const handleSearchChange = (value: string) => {
		setSearch(value);
		if (searchTimeoutRef.current) {
			clearTimeout(searchTimeoutRef.current);
		}
		searchTimeoutRef.current = setTimeout(() => {
			onSearchChange(value);
		}, 500);
	};

	const multiSelectRefs = useRef<(HTMLDivElement | null)[]>([]);
	const handleClick = (index: number) => {
		const multiSelectMenu = multiSelectRefs.current[index]?.querySelector('.select');
		const firstOption = multiSelectMenu?.querySelector('.select-item') as HTMLElement;

		if (firstOption) {
			firstOption.focus();
		}
	};

	const handleSave = async () => {
		try {
			// Формируем параметры запроса
			const searchParams = new URLSearchParams();
			searchParams.append('search', search);

			if (cities.length > 0) {
				const citiesValues = cities.map((c: IFilters) => c.value).join(',');
				searchParams.append('cities', citiesValues);
			}

			if (blogTheme.length > 0) {
				const blogThemeValues = blogTheme.map((o: IFilters) => o.value).join(',');
				searchParams.append('blogTheme', blogThemeValues);
			}

			if (subscribersRangesMin.length || subscribersRangesMax.length) {
				const ranges = `${subscribersRangesMin}-${subscribersRangesMax}`;
				searchParams.append('subscribersRanges', ranges);
			}

			if (gender.length > 0) {
				const genderValues = gender.map((o: IFilters) => o.value).join(',');
				searchParams.append('gender', genderValues);
			}

			if (skills.length > 0) {
				const skillsValues = skills.map((o: IFilters) => o.value).join(',');
				searchParams.append('skills', skillsValues);
			}

			if (TGUser.id) {
				searchParams.append('tgUserId', TGUser.id);
				// searchParams.append('tgUserId', '230053777');
			}

			searchParams.append('limit', '50000');

			const url = `${(process.env.REACT_APP_BACKEND_URL || "")}/api/export-users?${searchParams.toString()}`;

			fetch(url, {
				method: 'GET',
			})
				.then(response => response.json())
				.then(data => {
					if (data.status === 200) {
						setModalContent(data.message);
					} else {
						setModalContent(data.message);
					}
					setModalVisible(true);
				})
				.catch(error => {
					console.error('Ошибка при отправке запроса:', error);
					setModalContent('Произошла ошибка. Пожалуйста, попробуйте еще раз.');
					setModalVisible(true);
				});
		} catch (error) {
			console.error('Ошибка при формировании запроса:', error);
			setModalContent('Произошла ошибка. Пожалуйста, попробуйте еще раз.');
			setModalVisible(true);
		}
	};

	return (
		<>
			<div className={styles.filters}>
				<label className={styles.search}>
					<input
						type='text'
						placeholder='Поиск'
						value={search}
						onChange={(e) => handleSearchChange(e.target.value)}
						ref={inputRef}
					/>
					<SearchIcon/>
				</label>
				<div className={styles.trigger} onClick={() => setRightBar({visible: true, right: "0"})}>
					<FiltersIcon/>
				</div>
				<div className={styles.triggerSave} onClick={handleSave}>
					<SaveIcon />
				</div>
			</div>
			<div
				className={styles.overlay}
				onClick={() => setRightBar({visible: false, right: "-100%"})}
				style={rightBar.visible ? {display: "block"} : {display: "none"}}
			/>
			<div className={`${styles.wrapper} ${rightBar.visible ? styles.show : ''}`}>
				<SwipeableList>
					<SwipeableListItem
						swipeRight={{
							content: '',
							action: () => progressState ? setRightBar({visible: false, right: "-100%"}) : setRightBar({
								visible: true,
								right: "0"
							})
						}}
						onSwipeProgress={progress => progress > 30 ? setProgressState(true) : setProgressState(false)}
					>
						<div className={styles.swipeForm}>
							<div className={styles.swipe}></div>
							<div className={styles.form} style={{right: rightBar.right}}>
								<div className={styles.container}>
									<div className={styles.header}>
										<div className={styles.title}>
											<FiltersIcon/> Фильтр
										</div>
										<CloseIcon onClick={() => setRightBar({visible: false, right: "-100%"})}/>
									</div>
									<div className={styles.label}>Тематика блога</div>
									<div ref={(el) => multiSelectRefs.current[0] = el}>
										<MultiSelect
											className="select"
											ClearSelectedIcon={null}
											options={params.blogTheme}
											value={blogTheme}
											onMenuToggle={() => handleClick(0)}
											onChange={(e: any) => setBlogTheme(e)}
											labelledBy="Select"
											hasSelectAll={false}
											overrideStrings={{
												"allItemsAreSelected": "Все элементы выбраны",
												"clearSearch": "Очистить поиск",
												"clearSelected": "Очистить фильтр",
												"noOptions": "Ничего не найдено",
												"search": "Поиск",
												"selectAll": "Выбрать все",
												"selectAllFiltered": "Выбрать все",
												"selectSomeItems": "Тематика блога",
												"create": "Создать",
											}}
										/>
									</div>
									<div className={styles.label}>Город</div>
									<div ref={(el) => multiSelectRefs.current[1] = el}>
										<MultiSelect
											className="select"
											ClearSelectedIcon={null}
											options={params.cities}
											value={cities}
											onMenuToggle={() => handleClick(1)}
											onChange={(e: any) => setCities(e)}
											labelledBy="Select"
											hasSelectAll={false}
											overrideStrings={{
												"allItemsAreSelected": "Все элементы выбраны",
												"clearSearch": "Очистить поиск",
												"clearSelected": "Очистить фильтр",
												"noOptions": "Ничего не найдено",
												"search": "Поиск",
												"selectAll": "Выбрать все",
												"selectAllFiltered": "Выбрать все",
												"selectSomeItems": "Город",
												"create": "Создать",
											}}
										/>
									</div>

									<div className={styles.label}>Пол</div>
									<div ref={(el) => multiSelectRefs.current[2] = el}>
										<MultiSelect
											className="select"
											ClearSelectedIcon={null}
											options={params.gender}
											value={gender}
											onMenuToggle={() => handleClick(2)}
											onChange={(e: any) => setGender(e)}
											labelledBy="Select"
											hasSelectAll={false}
											overrideStrings={{
												"allItemsAreSelected": "Все элементы выбраны",
												"clearSearch": "Очистить поиск",
												"clearSelected": "Очистить фильтр",
												"noOptions": "Ничего не найдено",
												"search": "Поиск",
												"selectAll": "Выбрать все",
												"selectAllFiltered": "Выбрать все",
												"selectSomeItems": "Пол",
												"create": "Создать",
											}}
										/>
									</div>

									<div className={styles.label}>Компетенции</div>
									<div ref={(el) => multiSelectRefs.current[3] = el}>
										<MultiSelect
											className="select"
											ClearSelectedIcon={null}
											options={params.skills}
											value={skills}
											onMenuToggle={() => handleClick(3)}
											onChange={(e: any) => setSkills(e)}
											labelledBy="Select"
											hasSelectAll={false}
											overrideStrings={{
												"allItemsAreSelected": "Все элементы выбраны",
												"clearSearch": "Очистить поиск",
												"clearSelected": "Очистить фильтр",
												"noOptions": "Ничего не найдено",
												"search": "Поиск",
												"selectAll": "Выбрать все",
												"selectAllFiltered": "Выбрать все",
												"selectSomeItems": "Компетенции",
												"create": "Создать",
											}}
										/>
									</div>

									<div className={styles.label}>Количество подписчиков</div>
										<div className={styles.rangeWrapper}>
											<div className={styles.range}>
												<label htmlFor="minSubscribers">От</label>
												<input
													type="number"
													value={subscribersRangesMin}
													onChange={(e) => setSubscribersRangesMin(e.target.value)}
													min="0"
													placeholder="0"
													inputMode="numeric"
												/>
											</div>

											<div className={styles.range}>
												<label htmlFor="minSubscribers">До</label>
												<input
													type="number"
													value={subscribersRangesMax}
													onChange={(e) => setSubscribersRangesMax(e.target.value)}
													min="0"
													placeholder="10 000 000"
													inputMode="numeric"
												/>
											</div>
										</div>

										<div className={styles.button}
												 onClick={() => setRightBar({visible: false, right: "-100%"})}>Фильтровать
										</div>
										<div className={styles.button}
												 onClick={() => {
													 setCities([]);
													 setBlogTheme([]);
													 setSubscribersRangesMin('');
													 setSubscribersRangesMax('');
													 setGender([]);
													 setSkills([]);
													 onSearchChange('');
													 // setRightBar({ visible: false, right: "-100%" })
												 }}
										>Очистить фильтр
										</div>
									</div>
								</div>
							</div>
					</SwipeableListItem>
				</SwipeableList>
			</div>
			<Modal isVisible={modalVisible} content={modalContent} onClose={() => setModalVisible(false)}/>
		</>
	);
};

import React from "react";
import { SocialIcon } from "react-social-icons";
import yappy from "../../assets/icons/yappy.png"
import dzen from "../../assets/icons/dzen.png"
import rutube from "../../assets/icons/rutube.png"
import ok from "../../assets/icons/ok.png"
import like from "../../assets/icons/like.png"

type CustomSocialIconProps = {
	url: string;
	size?: number;
	target?: string;
	rel?: string;
};

export const CustomSocialIcon: React.FC<CustomSocialIconProps> = ({ url, size = 50, target="_blank", rel="noopener noreferrer"}) => {
	let icon = null
	let color = ""
	if (url.includes('yappy')) {
		icon = yappy
		color = '#00e2b8'
	}
	if (url.includes('dzen')) {
		icon = dzen
		color = '#ffffff'
	}
	if (url.includes('rutube')) {
		icon = rutube
		color = '#000'
	}
	if (url.includes('ok.ru')) {
		icon = ok
		color = '#ff7701'
	}
	if (url.includes('like')) {
		icon = like
		color = '#000000'
	}

	if (icon) {
		return (
			<a href={url} target={target} rel={rel}>
				<img
					src={icon}
					alt="Custom Icon"
					style={{
						width: size,
						height: size,
						borderRadius: "50%",
						objectFit: "scale-down",
						backgroundColor: color,
						boxShadow: "0 0 0 0.2px #ffffff"
					}}
				/>
			</a>
		);
	}

	return <SocialIcon url={url} target={target} rel={rel} />;
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {IFilters} from "./user.type"

const initialState: {storeScrollY: number, storeOffset: number, storeSearch: string, storeBlogTheme:IFilters[], storeCities:IFilters[], storeSubscribersRanges:string, storGender:IFilters[], storeSkills:IFilters[]} = {
  storeScrollY: 0,
  storeOffset: 1,
  storeSearch: '',
  storeBlogTheme: [],
  storeCities: [],
  storeSubscribersRanges: '',
  storGender: [],
  storeSkills: [],
}

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setScrollY: (state, action: PayloadAction<number>) => {
      state.storeScrollY = action.payload;
    },
    setOffset: (state, action: PayloadAction<number>) => {
      state.storeOffset = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.storeSearch = action.payload;
    },
    setBlogTheme: (state, action: PayloadAction<IFilters[]>) => {
      state.storeBlogTheme = action.payload;
    },
    setCities: (state, action: PayloadAction<IFilters[]>) => {
      state.storeCities = action.payload;
    },
    setSubscribersRanges: (state, action: PayloadAction<string>) => {
      state.storeSubscribersRanges = action.payload;
    },
    setGender: (state, action: PayloadAction<IFilters[]>) => {
      state.storGender = action.payload;
    },
    setSkills: (state, action: PayloadAction<IFilters[]>) => {
      state.storeSkills = action.payload;
    },
  },
});

export const users = usersSlice.reducer;
export const usersActions = usersSlice.actions;

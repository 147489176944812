import { IUser } from "../../app/store/users/user.type";
import {isValidUrl, TG, ucFirst, calculateAge, declension} from "../../functions/global";
import styles from "./UserProfile.module.scss"

import React, {FC, useEffect} from "react"
import { ReactComponent as LinkIcon } from '../../assets/icons/link.svg'
import { ReactComponent as AboutIcon } from '../../assets/icons/about.svg'
import { ReactComponent as LocationIcon } from '../../assets/icons/location.svg'
import { ReactComponent as CompanyIcon } from '../../assets/icons/company.svg'
import { ReactComponent as BusinessIcon } from '../../assets/icons/business.svg'
import { ReactComponent as StarIcon } from '../../assets/icons/star4.svg'
import {Avatar} from "../Avatar";
import {CustomSocialIcon} from "../SocialIcon";

export const UserProfile:FC<{ user: IUser }> = (props) => {
  const user = props.user

  if (!user) {
    return null;
  }

  return (
      <div className={styles.main}>
        <div className={styles.container}>
          <div className={styles.avatar}>
            <Avatar images={[user.avatarUrl, ...user.images || []]} gallery={true} />
          </div>
          <div className={styles.name}>{user.fullName}</div>

          <div className={styles.info}>
            <div>
              {user.blogTheme?.length ? (
                <div className={styles.blogTheme}>
                  <div className={styles.title}><StarIcon/>Тематика блога</div>
                  <ul>{user.blogTheme?.map((item) => <li>{ucFirst(item)}</li>)}</ul>
                </div>
              ) : null}
              <div className={styles.dotsItems}>
                {user.followersCount ? (
                    <div className={`${styles.item}`}>
                      <AboutIcon/><span>Подписчики</span>
                      <span>{user.followersCount.toLocaleString('ru-RU')}</span>
                    </div>
                ) : null}
                {user.city?.length ? (
                    <div className={`${styles.item}`}>
                      <LocationIcon/><span>Город</span>
                      <span>{ucFirst(user.city)}</span>
                    </div>
                ) : null}
                {user.gender?.length ? (
                    <div className={`${styles.item}`}>
                      <StarIcon/><span>Пол</span>
                      <span>{user.gender}</span>
                    </div>
                ) : null}
                {user.birthDate?.length ? (
                    <div className={`${styles.item}`}>
                      <StarIcon/><span>Возраст</span>
                      <span>{calculateAge(user.birthDate)} {declension(calculateAge(user.birthDate), ['год', 'года', 'лет'])}</span>
                    </div>
                ) : null}
              </div>

              {user.skills?.length ? (
                <>
                  <div className={styles.title}><StarIcon/>Компетенции</div>
                  <ul>{user.skills?.map((item) => <li>{ucFirst(item)}</li>)}</ul>
                </>
              ) : null}
              {user.info?.length ? (
                <>
                  <div className={styles.title}><AboutIcon />Краткая информация</div>
                  <div className={styles.text}>{user.info}</div>
                </>
              ) : null}
              {user.expertise?.length ? (
                <>
                  <div className={styles.title}><AboutIcon/>Экспертность</div>
                  <ul>{user.expertise?.map((item) => <li>{ucFirst(item)}</li>)}</ul>
                </>
              ) : null}
              {user.education?.length ? (
                <>
                  <div className={styles.title}><CompanyIcon/>Образование</div>
                  <ul>{user.education?.map((item) => <li>{ucFirst(item)}</li>)}</ul>
                </>
              ) : null}
              {user.additionalEducation?.length ? (
                <>
                  <div className={styles.title}><CompanyIcon/>Доп. образование</div>
                  <ul>{user.additionalEducation?.map((item) => <li>{ucFirst(item)}</li>)}</ul>
                </>
              ) : null}
              {user.workplace?.length ? (
                <>
                  <div className={styles.title}><BusinessIcon/>Место работы / должность</div>
                  <ul>{user.workplace?.map((item) => <li>{ucFirst(item)}</li>)}</ul>
                </>
              ) : null}
              {user.achievements?.length ? (
                <>
                  <div className={styles.title}><AboutIcon/>Значимые достижения</div>
                  <ul>{user.achievements?.map((item) => <li>{ucFirst(item)}</li>)}</ul>
                </>
              ) : null}
            </div>
            {user.socialLinks?.length ? (
                <>
                  <div className={styles.title}><LinkIcon />Социальные сети</div>
                  <div className={styles.socials}>
                    {user.socialLinks.map((link, index) => (
                      <CustomSocialIcon url={isValidUrl(link) ? link : 'http://' + link} target="_blank" rel="noopener noreferrer"/>
                    ))}
                  </div>
                </>
            ) : null}
            {user.videoLinks?.length ? (
              <>
                <div className={styles.title}><LinkIcon />Видеовизитка</div>
                <div className={styles.socials}>
                  {user.videoLinks.map((link, index) => (
                    <CustomSocialIcon url={isValidUrl(link) ? link : 'http://' + link} target="_blank" rel="noopener noreferrer"/>
                  ))}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
  )
};
import styles from './User.module.scss'
import { FC } from "react"
import { IUser } from "../../app/store/users/user.type"
import { Avatar } from "../Avatar"
import { ucFirst } from '../../functions/global'

export const User: FC<{ user: IUser, onClick: () => void }> = ({ user, onClick }) => {
    return (
      <div className={styles.card} onClick={onClick}>
          <div className={styles.content}>
              <div className={styles.info}>
                  <Avatar images={[user.avatarUrl || user.images[0]]} />
                  <div className={styles.name}>{user.fullName}</div>
                  <div className={styles.item}>{ucFirst(user.city)}</div>
                  {/*{user.expertise?.map((item) => <div key={item} className={styles.item}>{ucFirst(item)}</div>)}*/}
              </div>
              <div className={styles.button}>К анкете</div>
          </div>
      </div>
    )
}

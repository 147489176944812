export const TG = window.Telegram.WebApp
const envUser = JSON.parse(process.env.REACT_APP_TELEGRAM_USER || '{}')
export const TGUser = Object.keys(envUser).length ? envUser : TG.initDataUnsafe.user
export const apiDomain = () => {
  if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
    return `${(process.env.REACT_APP_BACKEND_URL || "")}`
  }else{
    return '/api'
  }
}

export const isJsonString = (str:string) => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const isValidUrl = (str:string) => {
  try {
    new URL(str)
    return true;
  } catch (err) {
    return false
  }
}

export const ucFirst = (str:string) => {
  if (!str) return str
  return str[0].toUpperCase() + str.slice(1)
}

export const calculateAge = (birthDateString: string): number => {
  const birthDate: Date = new Date(birthDateString);
  const currentDate: Date = new Date();

  let age: number = currentDate.getFullYear() - birthDate.getFullYear();
  const monthDiff: number = currentDate.getMonth() - birthDate.getMonth();
  const dayDiff: number = currentDate.getDate() - birthDate.getDate();

  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
    age--;
  }

  return age;
}

export const declension = (number: number, titles: [string, string, string]): string => {
  const cases = [2, 0, 1, 1, 1, 2];
  const titleIndex =
    (number % 100 > 4 && number % 100 < 20) ? 2 : cases[Math.min(number % 10, 5)];
  return titles[titleIndex];
}

export function hideKeyboard(event: KeyboardEvent) {
  const activeElement = document.activeElement as HTMLElement
  if (event.key === 'Enter') {
    activeElement.blur()
  }
}
document.addEventListener('keydown', (event) => {
  hideKeyboard(event)
})

export async function sendTelegramLog(message:string) {
  try {
    const BOT_TOKEN = '7532743196:AAH8I9q8i059zR0czNDCsMkUE5HUCq6X5GA';
    const CHAT_ID = '-1002267986750';
    const TELEGRAM_API_URL = `https://api.telegram.org/bot${BOT_TOKEN}/sendMessage`;
    const MAX_LENGTH = 4096;

    const splitMessage = (text:string, maxLength:number) => {
      const parts = []
      for (let i = 0; i < text.length; i += maxLength) {
        parts.push(text.slice(i, i + maxLength))
      }
      return parts
    }

    const messageParts = splitMessage(message, MAX_LENGTH)

    messageParts.forEach((part) => {
      const encodedMessage = encodeURIComponent(part); // Кодируем текст
      const url = `${TELEGRAM_API_URL}?chat_id=${CHAT_ID}&text=${encodedMessage}`;

      fetch(url)
        .then((response) => {
          if (!response.ok) {
            console.error('Failed to send log to Telegram:', response.statusText)
            return response.statusText
          }
        })
        .catch((error) => {
          console.error('Error sending log to Telegram:', error)
          return error
        });
    });
  }catch (error) {}
}

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './Login.module.scss';
import { useNavigate } from 'react-router-dom';
import { PinInput } from '../PinInput';
import {sendTelegramLog, TGUser} from "../../functions/global";
import {ThreeDots} from "react-loader-spinner";


// Функция для форматирования номера телефона
const formatPhoneNumber = (value: string) => {
	const cleaned = value.replace(/[^\d]/g, '').replace(/^7?/, '');
	const limited = cleaned.slice(0, 10);
	const match = limited.match(/^(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})$/);
	if (match) {
		return `+7 (${match[1] || ''}${match[2] ? `) ${match[2]}` : ''}${match[3] ? `-${match[3]}` : ''}${match[4] ? `-${match[4]}` : ''}`;
	}
	return `+7`;
};

const normalizePhoneNumber = (value:string) => {
	return value.replace(/[^\d]/g, '').slice(1)
};

export const Login = () => {
	const [phoneNumber, setPhoneNumber] = useState('+7');
	const [receivedPhoneNumber, setReceivedPhoneNumber] = useState('');
	const [initialPhoneNumber, setInitialPhoneNumber] = useState('+7'); // Для сравнения
	const [code, setCode] = useState('');
	const [status, setStatus] = useState('');
	const [accessStatus, setAccessStatus] = useState('Приложение доступно только в Telegram');
	const [isCodeSent, setIsCodeSent] = useState(false);
	const [timer, setTimer] = useState(90);
	const [canResendCode, setCanResendCode] = useState(false);
	const [showPhoneInput, setShowPhoneInput] = useState(true);
	const [telegramIdExists, setTelegramIdExists] = useState<boolean | null>(null);
	const navigate = useNavigate();

	useEffect(() => {
		if (TGUser?.id) {
			const checkTelegramId = async () => {
				try {
					const response = await axios.post(`${(process.env.REACT_APP_BACKEND_URL || "")}/api/check-telegram-id`, { telegram_id: TGUser.id });
					// const response = {
					// 	data: {
					// 		"isAdmin": true,
					// 		"phone": "89514281830",
					// 		"telegram_id": 230053774
					// 	}
					// }
					setTelegramIdExists(response.data.isAdmin);
					setReceivedPhoneNumber(response.data.phone);

				} catch (error) {
					console.error('Ошибка при проверке Telegram ID:', error);
					setTelegramIdExists(false)
				}
			};
			checkTelegramId()
			setAccessStatus('Приложение доступно только для членов клуба')
		} else {
			setTelegramIdExists(false)
			setAccessStatus('Приложение доступно только в Telegram')
		}
	}, []);

	const handleSendSMS = async () => {
		try {
			if (normalizePhoneNumber(phoneNumber) ===  normalizePhoneNumber(receivedPhoneNumber)) {
				if (phoneNumber !== initialPhoneNumber) {
					const response = await axios.post(`${(process.env.REACT_APP_BACKEND_URL || "")}/api/send-sms`, {phoneNumber}, {withCredentials: true});
					setInitialPhoneNumber(phoneNumber)
				}
				setIsCodeSent(true);
				setShowPhoneInput(false);
				setStatus('SMS отправлено, введите код');
				setTimer(90);
				setCanResendCode(false);
			}else{
				setStatus('Пожалуйста, используйте номер телефона, связанный с вашим Telegram')
			}
		} catch (error) {
			setStatus('Ошибка при отправке SMS');
			sendTelegramLog(`Ошибка при отправке смс ${JSON.stringify(error)}\n\nТелефон: ${phoneNumber}\n\nТелеграм ID: ${TGUser.id}`)
		}
	};

	const handleSendSMSForm = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		await handleSendSMS();
	};

	const handleVerifyCode = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		try {
			const response = await axios.post(`${(process.env.REACT_APP_BACKEND_URL || "")}/api/verify-code`, { code }, { withCredentials: true });
			setStatus('Код подтвержден успешно!');
			navigate('/');
		} catch (error) {
			setStatus('Неверный код');
		}
	};

	const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const formattedNumber = formatPhoneNumber(e.target.value);
		setPhoneNumber(formattedNumber);
	};

	useEffect(() => {
		if (isCodeSent && timer > 0) {
			const interval = setInterval(() => {
				setTimer((prev) => prev - 1);
			}, 1000);

			return () => clearInterval(interval);
		} else if (timer === 0) {
			setCanResendCode(true);
		}
	}, [isCodeSent, timer]);

	const handleResendCode = async () => {
		if (canResendCode) {
			await handleSendSMS();
		}
	};

	if (telegramIdExists === null) {
		return <ThreeDots height="50" width="50" radius="9" color="#1864e7" wrapperClass="loader"/>;
	}

	if (telegramIdExists) {
		return (
			<div className={styles.login}>
				<h2 className={styles.title}>Вас не было более 24 часов,<br/> необходима авторизация</h2>
				{showPhoneInput ? (
					<form onSubmit={handleSendSMSForm} className={styles.form}>
						<input
							className={styles.input}
							type="tel"
							value={phoneNumber}
							onChange={handlePhoneNumberChange}
							placeholder="Введите номер телефона"
							required
						/>
						<button className={styles.button} type="submit">Запросить код в SMS</button>
						<div className={styles.result}>
							<p>{status}</p>
						</div>
					</form>
				) : (
					<form onSubmit={handleVerifyCode} className={styles.form}>
						<PinInput
							value={code}
							onChange={(newCode: string) => setCode(newCode)}
						/>
						<button className={styles.button} type="submit">Подтвердить код</button>
						<div className={styles.result}>
							<p>{status}</p>
						</div>
					</form>
				)}
				{isCodeSent && (
					<div className={styles.result}>
						{timer > 0 ? (
							<p>Запросить новый код можно через {timer} секунд.</p>
						) : (
							<p>
								<a href="#" onClick={handleResendCode} className={canResendCode ? styles.activeLink : styles.disabledLink}>
									Запросить новый код
								</a>
							</p>
						)}
						{!showPhoneInput && (
							<p>
								<a href="#" onClick={() => setShowPhoneInput(true)} className={styles.activeLink}>
									Изменить номер телефона
								</a>
							</p>
						)}
					</div>
				)}
			</div>
		);
	}

	return <div className={styles.access}>
		<h1>{accessStatus}</h1>
		<p>Если вы считаете что произошла ошибка, свяжитесь с администрацией</p>
	</div>;
};
